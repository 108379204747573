@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.coupons {
  .row {
    .input-group {
      .form-control {
        background-color: v.$color4;
        border: none;
        padding: 0.7rem;
        &:focus {
          background-color: white;
          outline: 0;
          box-shadow: 0 0 0 0.1rem v.$color1;
        }
      }
      ::placeholder {
        font-size: 0.7rem;
      }
      span {
        background-color: v.$color1;
        border: 1px solid v.$color1;
        svg {
          color: white;
          font-size: 1.2rem;
        }
      }
    }
    .buttons{
      @include m.flexbox(row, center, stretch);
      flex: 1;
      padding-left: 0;
      .input-group{
        @include m.flexbox(row, flex-end, stretch);
        flex: 1;
      }
      .btn {
        color: white;
        border: none;
        padding: 0.7rem;
        width: 8.5rem;
        @media (max-width: 390px) {
          font-size: 0.8rem;
          padding: 0.85rem 0.4rem;
          width: 6.5rem;
        }
      }
    }
  }
  .rdt_Table {
    .rdt_TableHead {
      font-size: 0.9rem;
      .rdt_TableCol {
        font-weight: 600;
      }
    }
    .rdt_TableBody {
      :hover {
        background-color: v.$color4;
      }
    }
    .rdt_TableCell {
      &:hover {
        cursor: pointer;
      }
    }
  }

  // .rdt_TableHeadRow {
  //   font-weight: 700;
  //   margin-top: 1rem;
  //   font-size: 0.9rem;
  //   min-height: 48px;
  // }
  // .rdt_TableCol_Sortable {
  //   justify-content: center;
  // }
  // [data-column-id^="1"] {
  //   justify-content: flex-start;
  // }
  // .rdt_TableCell {
  //   justify-content: center;
  // }
  // [id^="cell-1-"] {
  //   justify-content: flex-start;
  // }
}
