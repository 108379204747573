@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.account {
  .account-row{
    margin-top: 3rem;
    @media (max-width: 992px) {
      margin-top: 0;
      transition: 1s;
    }
    @media (min-width: 992px) {
      margin-top: 3rem;
      transition: 1s;
    }
    @media (max-width: 768px) {
      margin-top: -3rem;
      transition: 1s;
    }
  }
  .account-form {
    background-color: white;
    border-radius: 0.3rem;
    form {
      text-align: center;
      border: none;
      .input-group {
        > span.input-group-text {
          border-top-left-radius: 0.3rem;
          border-bottom-left-radius: 0.3rem;
          svg {
            color: v.$color1;
          }
          &:first-child {
            svg {
              font-size: 1.3rem;
            }
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          > span.input-group-text {
            &:nth-child(3) {
              border-top-right-radius: 0.3rem !important;
              border-bottom-right-radius: 0.3rem !important;
            }
          }
        }
        input.form-control {
          background-color: v.$color4;
          &.email-input {
            border-top-right-radius: 0.3rem !important;
            border-bottom-right-radius: 0.3rem !important;
          }
        }
      }
      .btn {
        width: 30%;
        color: white;
        font-weight: 300;
        border-radius: 0.3rem;
      }
    }
  }
}
