@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.forgot-reset {
  @include m.flexbox(column, center, center);
  text-align: center;

  .forgot-reset-box {
    border-radius: 0.3rem;
    padding: 2rem 3rem;
    background-color: white;
    width: 30vw !important;
    @media (max-width: 992px) {
      width: 50vw !important;
    }
    @media (max-width: 776px) {
      width: 60vw !important;
    }
    @media (max-width: 576px) {
      width: 80vw !important;
    }
    @media (max-width: 450px) {
      width: 90vw !important;
    }
    @media (max-width: 400px) {
      width: 100vw !important;
    }
    h3 {
      font-weight: 600;
      color: v.$color3;
      margin-bottom: 1rem;
    }
    p {
      line-height: 1.7rem;
    }
    a {
      text-decoration: none;
      .btn-primary {
        border-radius: 0.3rem;
        color: white;
        width: 50%;
        background-color: v.$color1;
        &:hover {
          background-color: v.$color3;
          border-color: v.$color3;
        }
      }
      .btn-secondary {
        border-radius: 0.3rem;
        color: white;
        width: 50%;
        background-color: v.$color2;
        &:hover {
          background-color: v.$color3;
          border-color: v.$color3;
        }
      }
    }
  }
}
