@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.admin-user-edit-1 {
  .form-label {
    margin: 0;
  }
  .input-group-text{
    border: none;
  }
  .form-control {
    background-color: v.$color4;
    border: none;
    &:focus {
      background-color: white;
      outline: 0;
      box-shadow: 0 0 0 0.1rem v.$color1;
    }
  }
  .form-check-input{
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0 v.$color1;
    }
  }
  .btn-group {
    gap: 0.8rem;
    .btn {
      width: 8rem;
      color: white;
      font-size: 1.1rem;
      @media (max-width: 385px) {
        width: 7rem;
      }
    }
  }
  div.alert{
    @include m.flexbox(row, flex-start);
    padding: 0;
    border: 0;
    .alert-warning, .alert-danger {
      border: none;
      padding: 0.5rem;
    }
  }
}
