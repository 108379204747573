@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.profile {
  .profile-row {
    margin-top: 3rem;
    @media (max-width: 992px) {
      margin-top: 0;
      transition: 1s;
    }
    @media (min-width: 992px) {
      margin-top: 3rem;
      transition: 1s;
    }
    @media (max-width: 768px) {
      margin-top: -3rem;
      transition: 1s;
    }
  }
  .profile-page {
    //margin-bottom: 190px;
    border: 1px solid v.$color4;
    border-radius: 0.3rem;
    padding: 2rem 3rem 7rem 3rem;
    background-color: white;
    height: 70vh;
  }
  .input-group {
    > span.input-group-text {
      // input grubun icindeki cocugu olan spanler
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
      .svg {
        color: v.$color1;
      }
      &:first-child {
        svg {
          font-size: 1.3rem;
        }
      }
    }
    .form-control {
      background-color: v.$color4;
      border-radius: 0 0.3rem 0.3rem 0 !important;
      &.reg-form2 {
        border-top-right-radius: 0.3rem !important;
        border-bottom-right-radius: 0.3rem !important;
      }
    }

    &:nth-child(7),
    &:nth-child(8) {
      > span.input-group-text {
        &:nth-child(3) {
          border-top-right-radius: 0.3rem !important;
          border-bottom-right-radius: 0.3rem !important;
        }
      }
    }
  }
  .btn-secondary {
    width: 30%;
    color: white !important;
    font-weight: 300;
    border-radius: 0.3rem;
  }
}
