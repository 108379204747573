@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

.page-header {
  @include m.bg-image("../../../assets/img/slider/slider1.jpg");
  @include m.flexbox($align-items: flex-end);
  height: 100px;
  padding-bottom: 1rem;
  position: relative;
  margin-top: 64px;
  @media (max-width: 992px) {
    margin-top: 0;
  }
  @media (min-width: 992px) {
    margin-top: 64px;
  }
  &::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(360deg, v.$color6, rgb(255, 255, 255, 1));
    @media (max-width: 992px) {
      top: -2px;
    }
  }

  h1 {
    color: v.$color4;
    z-index: 1;
    font-size: 3rem;
    font-weight: 700;
    text-shadow: 0 0 30px v.$color1;
    margin-left: 2rem;

    @media (max-width: 600px) {
      font-size: 3rem;
    }

    @media (max-width: 500px) {
      font-size: 2.5rem;
    }

    @media (max-width: 400px) {
      font-size: 2rem;
    }
  }
}
