@use "../../../assets/scss/variables" as v;

.brands {
  background-color: white;
  padding: 2rem;
  img {
    width: 7rem;
    margin: 0 2rem;
    padding: 1rem 0;
  }
}
