@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.coupon-assign {
  .row {
    .form-control {
      background-color: v.$color4;
      border: none;
      padding: 0.7rem;
      &:focus {
        background-color: white;
        outline: 0;
        box-shadow: 0 0 0 0.1rem v.$color1;
      }
    }
    ::placeholder {
      font-size: 0.7rem;
    }
    .coupon-col {
      overflow-y: scroll;
      height: 200px;
      &::-webkit-scrollbar {
        width: 0.3rem;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 20px v.$color4;
      }
      &::-webkit-scrollbar-thumb {
        background-color: v.$color1;
      }
    }
    .searchbox {
      .form-control {
        background-color: v.$color4;
        border: none;
        padding: 0.7rem;
        &:focus {
          background-color: white;
          outline: 0;
          box-shadow: 0 0 0 0.1rem v.$color1;
        }
      }
      ::placeholder {
        font-size: 0.7rem;
      }
      .input-group-text {
        background-color: v.$color1;
        border: 1px solid v.$color1;
        svg {
          color: white;
          font-size: 1.2rem;
        }
      }
    }
    .selectbox {
      .form-select {
        background-color: v.$color4;
        border: none;
        padding: 0.8rem;
        font-size: 0.9rem;
        border-radius: 0;
        &:focus {
          outline: 0;
          box-shadow: 0 0 0 0.1rem v.$color1;
        }
        option {
          font-size: 0.9rem;
        }
      }
    }
    .form-check {
      padding-left: 2.6rem;
      .form-check-input {
        width: 0.9rem;
        height: 0.9rem;
        &:focus {
          outline: 0;
          box-shadow: 0 0 0 0 v.$color1;
        }
      }
      .form-check-label {
        font-size: 0.9rem;
      }
    }
    .user-col {
      overflow-y: scroll;
      height: 300px;
      &::-webkit-scrollbar {
        width: 0.3rem;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 20px v.$color4;
      }
      &::-webkit-scrollbar-thumb {
        background-color: v.$color1;
      }
    }
  }
  .rdt_Table {
    .rdt_TableHead {
      font-size: 0.9rem;
      .rdt_TableCol {
        font-weight: 600;
      }
    }
    .rdt_TableBody {
      :hover {
        background-color: v.$color4;
      }
    }
    .rdt_TableCell {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .btn-group {
    gap: 0.8rem;
    .btn {
      color: white;
      border: none;
      width: 10rem;
      @media (max-width: 390px) {
        font-size: 0.7rem;
        width: 7.5rem;
      }
    }
  }
}
