@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.coupon-new {
  .form-label{
    margin: 0;
  }
  .form-switch {
    @include m.flexbox(row, center, center, 1rem);
    .form-check-input {
      background-color: v.$color4;
      cursor: pointer;
      border: none;
      padding: 0.65rem 1.5rem;
      margin-top: 1.8rem;
      &:checked {
        background-color: v.$color2;
        border-color: v.$color2;
      }
      &:focus {
        outline: 0;
        box-shadow: 0 0 0 0 v.$color1;
      }
    }
    .form-check-label {
      font-size: 1.2rem;
      margin-top: 2rem;
    }
  }
  .form-control,
  .form-select {
    background-color: v.$color4;
    border: none;
    border-radius: 0;
    font-size: 1rem;
    &.life{
      border-right: 1px solid rgba(128, 128, 128, 0.343);
    }
    &:focus {
      background-color: white;
      outline: 0;
      box-shadow: 0 0 0 0.1rem v.$color1;
    }
  }
  .checkbox {
    background-color: v.$color4;
    margin: auto;
    padding: 7px;
    .form-check-input{
      &:focus {
        outline: 0;
        box-shadow: 0 0 0 0 v.$color1;
      }
    }
  }
  span.infinity {
    border: none;
    background-color: v.$color4;
  }
  .btn-group {
    gap: 0.8rem;
    .btn {
      width: 8rem;
      color: white;
      font-size: 1.1rem;
      @media (max-width: 385px) {
        width: 7rem;
      }
    }
  }
}
