@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.scroll-button {
  @include m.flexbox(row, center, center);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: v.$color1;
  color: white;
  border: none;
  position: fixed;
  right: 20px;
  bottom: 55px;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0 0 10px v.$color4;
  &.visible {
    opacity: 1;
  }
  svg {
    font-size: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
