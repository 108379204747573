@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
.searchbar {
  position: relative;
  margin-top: 65px;
  @media (max-width: 992px) {
    margin-top: 0;
  }
  @media (min-width: 992px) {
    margin-top: 65px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background-image: linear-gradient(
      360deg,
      v.$color6,
      rgba(255, 255, 255, 1)
    );
    @media (max-width: 992px) {
      top: -2px;
    }
  }

  img {
    object-fit: cover;
    height: 48vh;
    width: 100%;

    @media (max-width: 776px) {
      height: 45vh;
    }
    @media (max-width: 412px) {
      height: 40vh;
    }
    @media (max-width: 405px) {
      height: 35vh;
    }
    // @media (max-width: 768px) and (max-height: 1024px) {
    //   height: 35vh;
    // }
    // @media (max-width: 820px) and (max-height: 1180px) {
    //   height: 35vh;
    // }
    // @media (max-width: 834px) and (max-height: 1194px) {
    //   height: 30vh;
    // }
    // @media (max-width: 800px) and (max-height: 1280px) {
    //   height: 30vh;
    // }
    // @media (max-width: 884px) and (max-height: 1104px) {
    //   height: 32vh;
    // }
  }

  .content {
    margin: auto;
    width: 55%;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
    color: #1d3557;
    z-index: 5;

    // @media (max-width: 820px) and (max-height: 1180px) {
    //   top: 20% !important;
    // }
    // @media (max-width: 768px) and (max-height: 1024px) {
    //   top: 10% !important;
    // }
    // @media (max-width: 834px) and (max-height: 1194px) {
    //   top: 15% !important;
    //   width: 70% !important;
    // }
    // @media (max-width: 800px) and (max-height: 1280px) {
    //   top: 20% !important;
    //   width: 70% !important;
    // }
    // @media (max-width: 884px) and (max-height: 1104px) {
    //   top: 18% !important;
    //   width: 70% !important;
    // }
    @media (max-width: 992px) {
      top: 10%;
      left: 50%;
      width: 50%;
    }

    @media (max-width: 576px) {
      top: 15%;
      left: 50%;
      width: 60%;
    }
    @media (max-width: 405px) {
      top: 10%;
    }

    h2 {
      padding-bottom: 1.2rem;
      font-weight: 900;
      font-family: "Dancing Script", cursive;
      font-size: 70px;
      letter-spacing: 2px;

      @media (max-width: 992px) {
        font-size: 45px;
        letter-spacing: 0;
        font-weight: 600;
      }
      @media (max-width: 776px) {
        font-size: 35px;
      }
      @media (max-width: 405px) {
        font-size: 28px;
        padding: 0;
      }
      @media (max-width: 320px) {
        font-size: 27px;
      }
    }
    .input-group {
      .form-control {
        flex: 2 1 auto;
        border-radius: 0.3rem;

        @media (max-width: 776px) {
          width: 50%;
          border-radius: 0.3rem !important;
          padding-right: 5rem;
        }
      }

      .form-select {
        margin-right: -0.01rem;

        option {
          @media (max-width: 992px) {
            font-size: 0.8rem;
          }
        }

        @media (max-width: 776px) {
          border-radius: 0.3rem !important;
          width: 100%;
        }
      }
      .btn-svg {
        background-color: v.$color2;
        border: none;
        border-bottom-right-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        padding: 0.44rem 0.75rem;

        @media (max-width: 776px) {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 5;
        }

        svg {
          color: white;
          font-size: 1.2rem;
          font-weight: bolder;
        }
      }
    }
    .product-filter {
      @include m.flexbox(column, center, flex-start);
      margin-top: -1.15rem;
      background-color: white;
      border-radius: 5px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      .dataItem {
        text-decoration: none;
        text-align: left;

        p {
          padding: 0 0.75rem;
          margin: 0.5rem 0;
          &:hover {
            color: v.$color2;
          }
        }
      }
    }
  }
}
