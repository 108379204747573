@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.brands-edit {
  form {
    .image-area {
      @include m.flexbox(row, center);
      padding-top: 2rem;
      background-color: white;
      position: relative;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      @media (max-width: 768px) {
        min-height: 140px;
        padding-top: 0;
        margin-left: 0;
        margin-right: 0;
      }
      @media (width: 768px) {
        padding-top: 2rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
      img {
        @include m.flexbox(row, center);
        @media (max-width: 768px) {
          position: absolute;
          max-height: 70%;
        }
        @media (width: 768px) {
          position: static;
          height: auto;
        }
      }
      .select-img {
        background-color: v.$color1;
        color: white;
        position: absolute;
        margin: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        padding: 0.5rem;
        cursor: pointer;
        @media (max-width: 768px) {
          max-width: 90%;
          left: 10%;
          right: 10%;
        }
        @media (width: 768px) {
          max-width: 100%;
          left: 0;
          right: 0;
        }
      }
      .image-error {
        position: absolute;
        font-weight: 400;
        left: 50%;
        top: 40%;
        transform: translate(-50%);
      }
    }
    .input-select {
      .form-label {
        margin-bottom: 0;
      }
      .form-control {
        background-color: v.$color4;
        border: none;
        padding: 0.8rem;
        padding-left: 1.2rem;
        &:focus {
          background-color: white;
          outline: 0;
          box-shadow: 0 0 0 0.1rem v.$color1;
        }
      }
      ::placeholder {
        font-size: 0.7rem;
      }
      .form-switch {
        @include m.flexbox(row, flex-start, center, 1rem);
        .form-check-input {
          background-color: v.$color4;
          cursor: pointer;
          border: none;
          padding: 0.65rem 1.5rem;
          &:checked {
            background-color: v.$color2;
            border-color: v.$color2;
          }
          &:focus {
            outline: 0;
            box-shadow: 0 0 0 0 v.$color1;
          }
        }
        .form-check-label {
          font-size: 1.2rem;
          margin-top: 0.4rem;
        }
      }
      .btn-group {
        gap: 0.8rem;
        .btn {
          width: 8rem;
          color: white;
          font-size: 1.1rem;
          @media (max-width: 385px) {
            width: 7rem;
          }
        }
      }
    }
  }
  div.alert{
    @include m.flexbox(row, flex-start);
    padding: 0;
    border: 0;
    .alert-warning, .alert-danger {
      border: none;
      padding: 0.5rem;
    }
  }
}
