@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

.slider {
  .swiper-wrapper {
    .swiper-slide {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: -25px;
        right: 0;
        left: 0;

        background-image: linear-gradient(
          360deg,
          v.$color6,
          rgba(255, 255, 255, 1)
        );
        @media (max-width: 992px) {
          top: -2px;
        }
      }
    }
  }
}
