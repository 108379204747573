@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.favorites {
  .favorites-row {
    margin-top: 3rem;
    @media (max-width: 992px) {
      margin-top: 0;
      transition: 1s;
    }
    @media (min-width: 992px) {
      margin-top: 3rem;
      transition: 1s;
    }
    @media (max-width: 768px) {
      margin-top: -3rem;
      transition: 1s;
    }
  }
  .favorite {
    background-color: white;
    border-radius: 0.3rem;
    padding: 2rem;

    .card {
      border-radius: 0.3rem;
      border: none;
      padding: 0.5rem;
      margin: 1rem;
      @media (max-width: 768px) {
        text-align: center;
        margin: 0;
        padding: 0;
      }
      @media (min-width: 768px) {
        text-align: left;
        padding: 0.5rem;
        margin: 1rem;
      }
      &:hover svg {
        display: block !important;
      }
      &:nth-child(odd) {
        background-color: v.$color4;
      }
      .row {
        div {
          &:nth-child(1) {
            @include m.flexbox(column, center, center);
            .card-img-left {
              background-color: white;
              border-radius: 0.3rem;
              padding: .5rem;
              margin: 0.5rem;
              @media (max-width: 768px) {
                max-width: 20%;
                padding: 0.2rem;
              }
              @media (width: 768px) {
                max-width: 100%;
                padding: 0.5rem;
              }
              @media (max-width: 992px) {
                padding: .3rem;
              }
              @media (width: 992px) {
                padding: .5rem;
              }
            }
          }
          &:nth-child(2) {
            @include m.flexbox(column, center, flex-start);
            @media (max-width: 768px) {
              @include m.flexbox(column, center, center);
            }
            @media (min-width: 768px) {
              @include m.flexbox(column, center, flex-start);
            }
            a {
              text-decoration: none;
              .card-title {
                color: v.$color3;
                font-size: 1rem;
                padding: 1rem;
                margin: 1rem;
                @media (max-width: 768px) {
                  margin: 0;
                  padding: 0;
                  font-size: 0.9rem;
                }
                @media (min-width: 768px) {
                  font-size: 1rem;
                  padding: 1rem;
                  margin: 1rem;
                }
              }
            }
          }
          &:nth-child(3) {
            padding: 1rem;
            @include m.flexbox(row, flex-end, center, 1rem);
            @media (max-width: 768px) {
              @include m.flexbox(row, center, center, 1rem);
            }
            @media (min-width: 768px) {
              @include m.flexbox(row, flex-end, center, 1rem);
            }
            svg {
              font-size: 1.5rem;
              color: v.$color2;
              display: none;
              cursor: pointer;
              @media (max-width: 768px) {
                font-size: 1.2rem;
              }
              @media (min-width: 768px) {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }
    div {
      .btn-secondary {
        margin-right: 1rem;
        width: 8rem;
        border-radius: 0.3rem;
        color: white;
      }
    }
  }
}
