@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.check-filter {
  div {
    .card {
      border-top-right-radius: 0.3rem;
      border-top-left-radius: 0.3rem;
      border: none;
      background-color: rgb(225, 225, 225);

      .card-title {
        background-color: v.$color1;
        color: white;
        font-size: 1rem;
        font-weight: 400;
        padding: 0.7rem 2rem;
        border-top-right-radius: 0.3rem;
        border-top-left-radius: 0.3rem;
        white-space: nowrap;
        @media (max-width: 400px) {
          padding: 0.5rem;
        }
      }
      form {
        padding: 1.5rem;
        @media (max-width: 400px) {
          padding: 0.5rem;
        }
        .form-check-input {
          border: none;
          font-size: 0.7rem;
          margin-top: 0.4rem;
        }
        .form-check-label {
          font-size: 0.9rem;
          font-weight: 500;
          @media (max-width: 400px) {
            font-size: 0.8rem;
          }
          @media (max-width: 350px) {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}
