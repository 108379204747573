@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

.reset-password-container {
  @include m.flexbox(row, center, center);
  text-align: center;
  h3 {
    font-weight: 600;
    color: v.$color3;
  }
  form {
    width: 30vw !important;
    @media (max-width: 992px) {
      width: 50vw !important;
    }
    @media (max-width: 776px) {
      width: 80vw !important;
    }
    @media (max-width: 400px) {
      width: 100vw !important;
    }
    background-color: white;
    border-radius: 0.3rem;
  }

  .input-group {
    &:nth-child(2) {
      .input-group-text {
        background-color: v.$color4;
        border-radius: 0.3rem 0 0 0.3rem;
        svg {
          font-size: 1.5rem;
          color: v.$color1;
        }
      }
      .form-control {
        background-color: v.$color4;
        border-radius: 0 0.3rem 0.3rem 0;
      }
    }
    &:nth-child(3),
    &:nth-child(4) {
      .input-group-text {
        &:nth-child(1) {
          border-radius: 0.3rem 0 0 0.3rem;
          svg {
            font-size: 1.5rem;
            color: v.$color1;
          }
        }
        &:nth-child(3) {
          border-radius: 0 0.3rem 0.3rem 0 !important;
          svg {
            color: v.$color1;
          }
        }
      }
      .form-control {
        background-color: v.$color4;
      }
    }
  }

  .btn-secondary {
    width: 10rem;
    color: white !important;
    border-radius: 0.3rem;
  }

  a {
    color: v.$color2;
    text-decoration: none;
  }
}
