@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.admin-navbar {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px v.$color1;
  }
  &::-webkit-scrollbar-thumb {
    background-color: v.$color4;
  }
  @media (min-width: 992px) {
    height: 100vh;
    align-items: flex-start !important;
    position: fixed !important;
    top: 0;
    left: 0;
  }
  .container {
    @media (min-width: 992px) {
      flex-direction: column;
    }
    .navbar-brand {
      img {
        width: 100px;
        margin: 1rem;
        @media (min-width: 992px) {
          width: 180px;
          margin: 1.5rem;
        }
      }
    }
  }
  .navbar-nav {
    @media (min-width: 992px) {
      flex-direction: column !important;
    }
    .nav-link {
      color: v.$color4;
      padding: 0.4rem;
      @media (max-width: 991px) {
        margin-left: 1rem;
      }
      @media (min-width: 990px) {
        margin-left: 1rem;
      }
      @media (min-width: 992px) {
        margin-left: 0;
      }
      &:hover {
        color: v.$color2;
      }
    }
  }
}