@use "../../../../assets/scss/variables" as v;
@use "../../../../assets/scss/mixins" as m;

.header-navbar {
  background-color: white;
  @include m.flexbox(row, space-between, center);
  @media (max-width: 992px) {
    position: relative;
  }

  .image {
    cursor: pointer;
    z-index: 12;
    @media (max-width: 992px) {
      margin-top: 0.5rem;
    }
    @media (max-width: 450px) {
      margin-top: 0.8rem;
    }
    img {
      width: 230px;
      margin-left: 2rem;
      @media (max-width: 450px) {
        margin-left: 0.5rem;
        width: 200px;
      }
      @media (max-width: 360px) {
        margin: 0;
        width: 180px;
      }
    }
  }

  .menu-basket-fav {
    z-index: 11;
    margin-right: 2rem;
    @include m.flexbox(row, space-between, center);
    .menubar {
      @media (max-width: 992px) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
      .navbar {
        position: relative;
        .container {
          @include m.flexbox(row, flex-end, center);
          padding: 0;
          .navbar-toggler {
            border-radius: 0.3rem;
            font-size: 1rem;
            position: absolute;
            top: 0.8rem;
            right: 8.5rem;
            @media (max-width: 450px) {
              right: 6rem;
            }
            @media (max-width: 360px) {
              right: 5.5rem;
              font-size: 0.8rem;
            }
          }
          .navbar-collapse {
            .navbar-nav {
              border-radius: 0.35rem;
              @media (max-width: 992px) {
                position: absolute;
                right: 0;
                left: 0;
                top: 3.2rem;
                background-color: white;
                width: 75%;
                margin: auto;
              }
              @media (width: 992px) {
                position: static;
                @include m.flexbox(row, flex-end, center);
                flex: 1;
                background-color: rgba(0, 0, 0, 0);
                width: auto;
                margin: 0;
                margin-right: 7.5rem !important;
              }
              @media (max-width: 600px) {
                width: 100%;
              }
              a {
                text-decoration: none;
                @include m.flexbox(row, flex-start, center, 0.2rem);
                @media (max-width: 992px) {
                  margin-bottom: 0;
                  padding: 0.3rem;
                }
                svg {
                  margin-top: -2px;
                  margin-left: 1rem;
                }
              }
              ///shop
              .dropdown-menu {
                border-radius: 0.3rem;
                margin: 0;
                padding: 0;

                .dropdown-divider {
                  margin: 0;
                }
                a {
                  &:hover {
                    color: v.$color2;
                  }
                  color: v.$color1;

                  @media (max-width: 992px) {
                    padding-left: 2.5rem;
                  }
                }
                @media (max-width: 992px) {
                  background: white;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
    .basket-favorite {
      @include m.flexbox();

      .btn-basket {
        background-color: rgba(255, 255, 255, 0.001);
        border: none;
        color: v.$color1;
        border-radius: 50px;
        font-size: 1.5rem;
        position: relative;

        &:hover {
          color: v.$color2;
        }
        span {
          color: white;
          background-color: v.$color1;
          position: absolute;
          top: 0.75rem;
          left: 1.75rem;
          right: 0.25rem;
          border-radius: 50%;
          font-size: 0.6rem;
          font-weight: 700;
          @media (max-width: 360px) {
            font-size: 0.5rem;
          }
        }
        @media (max-width: 992px) {
          position: absolute;
          right: 2rem;
          top: 0;
          bottom: 0;
          margin-top: 0.2rem;
        }
        @media (max-width: 450px) {
          right: 0.4rem;
        }
        @media (max-width: 360px) {
          right: 0;
          font-size: 1.3rem;
        }
      }
      .btn-favorite {
        background-color: rgba(255, 255, 255, 0.001);
        border: none;
        color: v.$color1;
        border-radius: 50px;
        font-size: 1.5rem;
        position: relative;
        margin-left: 0.5rem;

        &:hover {
          color: v.$color2;
        }
        span {
          color: white;
          background-color: v.$color1;
          position: absolute;
          top: 0.75rem;
          left: 1.75rem;
          right: 0.25rem;
          border-radius: 50%;
          font-size: 0.6rem;
          font-weight: 700;
          @media (max-width: 360px) {
            font-size: 0.5rem;
          }
        }
        @media (max-width: 992px) {
          position: absolute;
          right: 5rem;
          top: 0;
          bottom: 0;
          margin-top: 0.2rem;
        }
        @media (max-width: 450px) {
          right: 3rem;
        }
        @media (max-width: 450px) {
          right: 2.5rem;
        }
        @media (max-width: 360px) {
          font-size: 1.3rem;
        }
      }
    }
  }
}
