@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

.loginDiv {
  @include m.flexbox(row, center, center);
  text-align: center;
  h3 {
    font-weight: 600;
    color: v.$color3;
  }
  form {
    width: 30vw !important;
    @media (max-width: 992px) {
      width: 50vw !important;
    }
    @media (max-width: 776px) {
      width: 80vw !important;
    }
    @media (max-width: 400px) {
      width: 100vw !important;
    }
    background-color: white;
    border-radius: 0.3rem;
    .form-control {
      background-color: v.$color4;
    }
    .input-group {
      > span.input-group-text {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        svg {
          color: v.$color1;
        }
        &:first-child {
          svg {
            font-size: 1.3rem;
          }
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        > span.input-group-text {
          &:nth-child(3) {
            border-top-right-radius: 0.3rem !important;
            border-bottom-right-radius: 0.3rem !important;
          }
        }
      }
      input.form-control {
        &.email-input {
          border-top-right-radius: 0.3rem !important;
          border-bottom-right-radius: 0.3rem !important;
        }
      }
    }
    .rememberForgot {
      @include m.flexbox(row, space-between, center);
      .form-check-input {
        margin-top: 0.3rem;
        font-size: 0.8rem;
      }
      .form-check-label {
        font-size: 0.9rem;
        color: v.$color3;
      }
    }
    .form-text {
      font-size: 0.85rem;
      &:nth-child(2) {
        cursor: pointer;
        color: v.$color2;
      }
    }
    .forgotText {
      color: v.$color2;
      cursor: pointer;
      font-size: 0.9rem;
    }
    .btn {
      border-radius: 0.3rem;
      color: white;
      width: 50%;
      &:last-child {
        background-color: v.$color3;
        &:hover {
          background-color: v.$color1;
        }
      }
    }
  }
}
