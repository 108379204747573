@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.forgot {
  @include m.flexbox(
    $direction: column,
    $justify-content: center,
    $align-items: center
  );

  .forgot-form {
    border-radius: 0.3rem;
    padding: 1rem 2rem;
    background-color: white;
    width: 30vw !important;
    @media (max-width: 992px) {
      width: 50vw !important;
    }
    @media (max-width: 776px) {
      width: 80vw !important;
    }
    @media (max-width: 400px) {
      width: 100vw !important;
    }
    .frm {
      h3 {
        text-align: center;
        font-weight: 600;
        color: v.$color3;
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }

      .input-group {
        svg {
          color: v.$color1;
          border-radius: 0.3rem;
          font-size: 1.5rem;
        }

        .input {
          width: 52%;
          color: v.$color2;
          border-top-right-radius: 0.3rem !important;
          border-bottom-right-radius: 0.3rem !important;
          background-color: v.$color4;
          color: black;
        }

        .input-group-text {
          border-top-left-radius: 0.3rem !important;
          border-bottom-left-radius: 0.3rem !important;
        }
      }
      .btn {
        margin-left: 1rem;
        width: 10rem;
        height: 2rem;
        color: white !important;
        padding-bottom: 2rem;
        border-radius: 0.3rem;
      }
    }
  }

  .form-text {
    > span {
      color: v.$color2;
      cursor: pointer;
    }
  }
}
