@use "../../assets/scss/variables" as v;
@use "../../assets/scss/mixins" as m;

.loading-page {
  background-color: v.$color1;
  width: 100vw;
  height: 100vh;
  @include m.flexbox(row, center, center, 0);

  img {
    width: 200px;
    height: auto;
  }

  .spinner-border {
    width: 20rem;
    height: 20rem;
    color: white !important;
    position: absolute;
  }
}
