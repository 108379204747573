@use "../../../assets/scss/variables" as v;

.loading {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 2rem;
  text-align: center;

  .spinner-border {
    color: v.$color1;
  }
}
