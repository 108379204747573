@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

.contact-message {
  .container {
    @media (max-width: 992px) {
      max-width: 900px;
    }
    @media (max-width: 767px) {
      max-width: 600px;
    }
  }
  .row {
    @include m.flexbox(row, space-between, center);

    .card {
      border-radius: 1rem;
      border: none;
      background-color: rgb(204, 201, 201);

      @media (max-width: 992px) {
        margin-bottom: 4rem;
      }
      .card-text {
        font-size: 1.2rem;
        a {
          @media (max-width: 992px) {
            font-size: 0.95rem;
          }
          @media (max-width: 767px) {
            font-size: 80%;
          }
          @media (min-width: 991px) {
            font-size: 0.95rem;
          }
        }
      }
      .card-header {
        @include m.flexbox(row, center, center);
        border-bottom: none;
        padding: 0;
        svg {
          font-size: 7rem;
          border: 1px solid v.$color2;
          border-radius: 5rem;
          padding: 1rem;
          background-color: v.$color2;
          color: v.$color4;
          position: absolute;
        }
      }
      .card-body {
        @include m.flexbox(column, flex-end, center);
        padding: 3.5rem;
        position: relative;
        .card-text {
          font-size: 1.2rem;
          position: absolute;
          top: 60%;
          bottom: -50%;
          a {
            text-decoration: none;
            color: v.$color1;
            font-weight: 700;
          }
        }
      }
    }
  }

  .img-form {
    @include m.flexbox(row, center, center);
    margin-top: 5rem;
    @media (max-width: 992px) {
      margin-top: 0;
    }
    img {
      width: 100%;
      @media (max-width: 768px) {
        display: none;
      }
      @media (width: 768px) {
        display: inline;
      }
    }
  }
  p {
    text-align: right;
    font-size: 1.3rem;
    @media (max-width: 991px) {
      font-size: 1rem;
    }
    @media (max-width: 767px) {
      text-align: justify;
    }
  }
  .form-control {
    border-radius: 0.3rem;
  }
  button {
    border-radius: 0.3rem;
    color: white !important;
  }
}
