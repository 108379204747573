@use "../../../assets/scss/mixins" as m;
@use "../../../assets/scss/variables" as v;

.cart {
  background-color: white;
  border-radius: 0.3rem;
  &.cart-padding {
    @media (max-width: 576px) {
      padding: 0.5rem !important;
    }
  }
  position: relative;
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-style: none;
  }
  table {
    @media (max-width: 767px) {
      font-size: 0.8rem;
    }
    thead {
      th {
        background-color: v.$color4;
        border-bottom: 6px solid white;
        font-weight: 500;
        vertical-align: middle;
        white-space: nowrap;
        border-radius: 0.3rem;
        &:nth-child(2) {
          text-align: center;
          border-left: 6px solid white;
          border-right: 6px solid white;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          text-align: right;
          border-left: 6px solid white;
        }
      }
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: v.$color4;
        }
        td {
          text-align: center;
          &:nth-child(1) {
            text-align: start;
            img {
              padding: 0.5rem;
              background-color: white;
              width: 100px;
              @media (max-width: 767px) {
                width: 125px;
              }
              @media (max-width: 576px) {
                width: 100px;
              }
              @media (max-width: 400px) {
                width: 125px;
                padding: 0.05rem;
              }
            }
          }
          &:nth-child(2) {
            text-align: start;
            @media (max-width: 450px) {
              font-size: 0.7rem;
            }
          }
          &:nth-child(3) {
            span {
              font-weight: 500;
              margin: 0 0.1rem;

              svg {
                font-size: 1.5rem;
                cursor: pointer;
                @media (max-width: 767px) {
                  font-size: 1rem;
                }
              }
            }
          }
          &:nth-child(4) {
            text-align: right;
            font-weight: 600;
          }
          &:nth-child(5) {
            background-color: white;
            svg {
              color: v.$color2;
              font-size: 1.5rem;
              cursor: pointer;
              @media (max-width: 767px) {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    tfoot {
      tr {
        &:nth-child(1),
        &:nth-child(2) {
          td {
            text-align: right;
            &.table-group-divider {
              border-top: 2px solid v.$color3;
            }

            &:nth-child(3) {
              white-space: nowrap;
              font-size: 0.8rem;

              h5 {
                margin-top: 1.2rem;
                font-size: 1.2rem;
                @media (max-width: 767px) {
                  font-size: 0.9rem;
                }
              }
              p {
                padding-right: 2rem;
                font-size: 0.8rem;
                margin-top: -0.3rem;
                @media (max-width: 767px) {
                  font-size: 0.7rem;
                  padding-right: 1rem;
                }
              }
              hr {
                position: absolute;
                top: auto;
                left: 0;
                right: 0;
                width: 92%;
                bottom: auto;
                margin: auto;
                border: 1px solid v.$color1;
                @media (max-width: 576px) {
                  bottom: 9rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .btn {
    width: 10rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: white;
    --bs-btn-hover-color: white;
    border-radius: 0.3rem;
    margin-right: 3rem;
    @media (max-width: 992px) {
      margin-right: 2rem;
    }
    @media (max-width: 576px) {
      width: 8rem;
    }
  }

  .sub-tax-discount-grand {
    @include m.flexbox(row, flex-end);
    border-top: 2px solid v.$color5;
    tbody {
      @include m.flexbox(row, flex-end, flex-end, 2rem);
      tr:nth-child(1) {
        background-color: white;
      }
      td {
        @include m.flexbox(row, flex-end);
        margin-top: 1rem;
        position: relative;
        font-size: 1.2rem;
        white-space: nowrap;
        @media (max-width: 500px) {
          font-size: 0.8rem;
        }
        &:nth-child(4) {
          font-weight: 400;
        }
        span {
          font-size: 0.75rem;
          position: absolute;
          right: 0;
          left: 4.5rem;
          top: 1.5rem;
          @media (max-width: 500px) {
            font-size: 0.6rem;
            left: 3rem;
            top: 1.2rem;
          }
        }
      }
    }
  }
}
